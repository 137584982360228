import React, { useEffect } from "react"

const QuizPage = () => {
  useEffect(() => {
    window.location.replace("https://app.lungscreen.foundation/quiz")
  }, [])
  return <div>Redirecting...</div>
}

export default QuizPage
